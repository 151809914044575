













































import Base from '@/mixins/Base.vue';
import { ITip, ITipResponse } from '@/interfaces/tip';

const component = Base.extend({
    data() {
        return {
            active: false,
            confirmation: '',
            tip: undefined as any | ITip,
        };
    },
    computed: {
        validated() : boolean {
            return this.confirmation === this.tip.summary.toLowerCase().substr(0, 5);
        },
    },
    mounted() {
        this.active = true;
        this.gettip();
    },
    methods: {
        gettip(): void {
            this.get<ITipResponse>(`tips/${this.$route.params.id}`).then(({ data }) => {
                this.tip = data.data;
            });
        },
        save(): void {
            if (this.validated) {
                this.delete(`tips/${this.tip.id}`).then(() => {
                    this.$router.push({ name: 'tips.list' });
                    this.$emit('reload');
                });
            }
        },
    },
});

export default component;
